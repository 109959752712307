.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.shopping-style {
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
}


.shopping-style:hover {
  background-color: #538DD5;
}

nav {
  background-color: #fff;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

a {
 
  color: black;
  padding: 10px 12px;
  text-decoration: none;
}

/* Change color on hover */
a:hover {
  background-color: #ddd;
  color: black;
}

.menu-header {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: #fff;
}

.page {
  background-color: #e5e7eb;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 20px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #fff;
}

li {
  float: left;
}

li a,
.dropbtn {
  display: inline-block;
  color: black;
  text-align: center;
  padding: 10px 12px;
  font-weight: 600;
  text-decoration: none;
}

li a:hover,
.dropdown:hover .dropbtn {
  background-color: teal;
  color: white;
}

li.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: teal;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

}

.dropdown-content a {
  color: white;
  padding: 10px 12px;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px white solid;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.container-no {
  margin-top: 20px;
}

.container-color {
  /* margin-left: 5%;
  margin-right: 5%; */
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: white;
}

.container-colo-inside {
  margin-top: 20px;
}

.card-product {
  cursor: pointer;
  width: 100%;
  height: 300px;

}

.card-product:hover {
  border: 1px blue solid;
}

.card-product-boder {
  border: 1px gray solid;
  cursor: pointer;
  width: 100%;
  height: 300px;
}

.card-product-boder:hover {
  border: 1px blue solid;
}

.container-footer {
  background-color: teal;
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 15px;

}

.text-support {
  color: #fff;
  margin-bottom: 10px;
  font-weight: 700;
}

.li-support {
  color: #fff;
  cursor: pointer;
  padding-top: 3px;
  margin-bottom: 3px;
}

.li-support:hover {
  color: goldenrod;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #538DD5;
  /* Add your preferred background color */
  text-align: center;
  justify-content: center;
}

.btn-add {
  background-color: darkblue;
  color: white;
  border-radius: 5px;
  padding: 8px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.btn-add:hover {
  background-color: darkslateblue;
}

input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  box-sizing: border-box;
  width: 60px;
  font-size: 18px;
  margin-right: 8px;
}

.cls-page {
  display: flex;
  width: 100%;
  text-align: end;
  margin-top: 20px;
  justify-content: flex-end;
  background-color: #e5e7eb;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 36px;
}

.page-admin {
  margin-left: 220px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  padding: 5px 10px;
}

.btn-save {
  background-color: darkslateblue;
  color: white;
  padding: 8px 10px;
  border: none;
  width: 80px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
}

.btn-save:hover {
  background-color: #538DD5;
}

.cls-btn-save {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;

}

.text-product {
  max-height: 3.5em;
  /* Độ cao tối đa tương đương với 3 dòng văn bản */
  overflow: hidden;
  /* Ngăn chặn tràn dòng */
  line-height: 1.2em;
  font-weight: 600;
  color: blue;
  /* Độ cao của mỗi dòng văn bản */
}

.table-cart {
  border-radius: 10px;
  margin-top: 10px;
  background-color: #fff;
}

.txt-quantily {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  width: 40px;
  font-size: 14px;
  text-align: center;
}

.btn-sub {
  margin-right: 8px;
  width: 30px;
  cursor: pointer;
}

.table-footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 30px;
}

.btn-muahang {
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  background-color: #1250dc;
  border: none;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  height: 35px;
}

.txt-discount {
  height: 28px;
  width: 80px;
  font-size: 15px;
}

.btn-muahang:hover {
  background-color: #538DD5
}

.container-infor {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.txt-name {
  width: 90%;
}

/* .scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top:hover {
  background-color: #0056b3;
} */


.fixed {
  border-color: #ccc;
  /* left: 77%; */
  margin-left: 18px;
  /* position: fixed; */
  top: 1%;
  -webkit-transform: translateX(-50%);
  transform: translateX(1%);
  z-index: 1000;
}

.fixed,
.fixedCard {
  border-radius: 10px;
  width: 300px;
}

.fixedCard {
  background-color: #fff;
  margin-left: 15px;
  padding: 15px;
  width: 300px;
}

.pay-button {
  background-color: #007bff;
  border: none;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
}

.txt-fill {
  width: 100%;

}


.breadcrumbs {
  margin: 20px 0;
  font-size: 14px;
}

.breadcrumbs a {
  text-decoration: none;
  color: #007BFF;
  transition: color 0.3s ease;
  display: flex;
}

.breadcrumbs a:hover {
  color: #0056b3;
}

.breadcrumbs span {
  color: #333;
}

.bt-add {
  cursor: pointer;
  border: none;
  background-color: #0056b3;
  color: white;
  width: 70px;
  border-radius: 5px;
}

table {
  width: 60%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Border cho cả bảng */
table.custom-border {
  border: 2px solid #3498db;
}

/* Border riêng cho các ô tiêu đề */
th.custom-border {
  border-bottom: 2px solid #3498db;
}

/* Border riêng cho ô cuối cùng của mỗi hàng */
/* td:last-child {
  border-right: 1px solid #3498db;
} */

/* Border riêng cho ô đầu tiên của mỗi hàng */
td:first-child {
  /* border-left: 1px solid #3498db;
  border-left: 1px solid #3498db; */
}

.bt-menu-edit {
  cursor: pointer;
  padding: 3px;
  width: 50px;
  margin-right: 8px;
  background-color: #1250dc;
  color: white;
  border: none;
  border-radius: 5px;
}

.bt-menu-delete {
  cursor: pointer;
  padding: 3px;
  width: 50px;
  margin-right: 8px;
  background-color: #9A0000;
  color: white;
  border: none;
  border-radius: 5px;
}
.bt-menu-detail {
  cursor: pointer;
  padding: 3px;
  width: 50px;
  margin-right: 8px;
  background-color: teal;
  color: white;
  border: none;
  border-radius: 5px;
}

.bt-add-brand {
  cursor: pointer;
  border: none;
  background-color: #1250dc;
  color: white;
  padding: 3px 5px;
  width: 70px;
  height: 30px;
  margin-right: 20px;
  border-radius: 5px;
}
.btn-delete-cart{
  background-color: firebrick;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 5px;
  transition: background-color 0.3s; /* Hiệu ứng chuyển động màu nền */
}
.btn-delete-cart:hover{
  background-color: #9A0000;
}
.sharp-image{
  filter: blur(0); /* Giảm độ mờ */
  width: 180px;
  height: 180px;
  image-rendering: pixelated;
}
.img-product-detail{
  width: 300px;
}
.header-page{
  display: flex;
  margin-top: 5px;
  height: 45px;
}
.bt-upload-img{
  padding: 5px;
  cursor: pointer;
}
.page-login{
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  height: 100vh;
  background-image: url('https://itc.haui.edu.vn/media/85/t85333.jpg');
}
.cls-company{
  background-color: #E26B0A;
}
.bt-delete-order{
  background-color: red;
  cursor: pointer;
  border: none;
  padding: 5px;
  margin-right: 8px;
  border-radius: 5px;
  color: white;
}
.btn-new-post{
  cursor: pointer;
  border: none; 
  background-color: #0056b3;
  color: white;
  padding: 10px;
  height: 40px;
  border-radius: 5px;
}
.by {
  color: #a3a2a2;
  cursor: pointer;
  margin-bottom: 10px;
}

.policy-list {
  list-style-type: disc;
  padding: 0;
  margin: 0;
  background-color: teal;
  display: grid;
}

/* CSS cho mục danh sách trong danh sách có class "policy-list" */
.policy-list .li-support {
  display: block; /* Hiển thị mỗi mục danh sách là một khối dọc */
  padding: 3px;
  border-radius: 4px;
  margin-bottom: 5px; /* Khoảng cách giữa các mục danh sách */
}
.ql-align-center{
  text-align: center;
  /* justify-content: center; */
}